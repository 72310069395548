import React from 'react'; // eslint-disable-line
import { useState, useEffect } from 'react';
import Header from './components/header';
import Footer from './components/footer';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Checkout from './checkout/checkout';
import './assets/css/styles.css'
import './assets/css/footer-pages-styles.css'
import ThankYou from './checkout/thankyou';
import { ProductsPage } from './home/productsPage';
import Privacy from './privacy-cookies/privacy';
import PrivacyCalifornia from './privacy-cookies/california';
import TermsAndConditions from './terms-of-use/termsAndConditions';
import http from './services/http';
import NotFound from './notfound/notFound';

const App = () => {

	const [config, setConfig] = useState();
	const getConfig = async () => {
		try {
			const baseURL = window.location.origin;
			const result = await http.get(baseURL + '/js/config.json');
			return result.data;
		}
		catch (e) {
			console.log(e)
		}
	}

	//TODO: setup sentry configuration

	useEffect(() => {
		(async () => {
			const configData = await getConfig();
			setConfig(configData);
			sessionStorage.setItem('config', JSON.stringify(configData));
			http.loadBaseUrl();

			const pageUrl = configData.assetsUrl + '/contents/headerScript.json';
			fetch(pageUrl)
			.then(response => response.text())
			.then(text => {
				if(text && text.length > 0){
					handleHeaderScripts(JSON.parse(text));
				}
			});

			const bodyTopUrl = configData.assetsUrl + '/contents/bodyTopScript.json';
			fetch(bodyTopUrl)
			.then(response => response.text())
			.then(text => {
				if(text && text.length > 0){
					handleBodyTopScripts(JSON.parse(text));
				}
			});

			const bodyBottomUrl = configData.assetsUrl + '/contents/bodyBottomScript.json';
			fetch(bodyBottomUrl)
			.then(response => response.text())
			.then(text => {
				if(text && text.length > 0){
					handleBodyBottomScripts(JSON.parse(text));
				}
			});

		})();
	}, []);

	const handleHeaderScripts = async (tagsList: any) => {
		tagsList.tags.forEach(tag => {
			const elementScript = document.getElementById(tag.id)
			elementScript?.remove();

			const script = document.createElement(tag.type);
			if (tag.content)
				script.innerHTML = tag.content;

			if (tag.src)
				script.src = tag.src;

			if (tag.customProps && Object.keys(tag.customProps).length > 0) {
				Object.keys(tag.customProps).forEach(prop => {
					script[prop] = tag.customProps[prop]
				})
			}
			script.id = tag.id
			document.head.appendChild(script);
		})
	}

	const handleBodyTopScripts = async (tagsList: any) => {
		tagsList.tags.forEach(tag => {
			const elementScript = document.getElementById(tag.id)
			elementScript?.remove();
			const element = document.createElement(tag.type);
			if (tag.content)
				element.innerHTML = tag.content;

			if (tag.src)
				element.src = tag.src;

			if (tag.customProps && Object.keys(tag.customProps).length > 0) {
				Object.keys(tag.customProps).forEach(prop => {
					element[prop] = tag.customProps[prop]
				})
			}
			element.id = tag.id
			document.body.insertBefore(element, document.body.firstChild);
		})
	}

	const handleBodyBottomScripts = async (tagsList: any) => {
		tagsList.tags.forEach(tag => {
			const elementScript = document.getElementById(tag.id)
			elementScript?.remove();
			const element = document.createElement(tag.type);
			if (tag.content)
				element.innerHTML = tag.content;

			if (tag.src)
				element.src = tag.src;

			if (tag.customProps && Object.keys(tag.customProps).length > 0) {
				Object.keys(tag.customProps).forEach(prop => {
					element[prop] = tag.customProps[prop]
				})
			}
			element.id = tag.id
			document.body.appendChild(element);
		})
	}

	return (
		<div className="App">
			{config ?
				<>
					<Header />
					<BrowserRouter>
						<Routes>
							<Route path="/checkout/:trackType/:trackPage" element={<Checkout config={config} />} />
							<Route path="/checkout/thankyou" element={<ThankYou config={config} />} />
							<Route path="/privacy" element={<Privacy config={config} />} />
							<Route path="/privacy/california" element={<PrivacyCalifornia config={config} />} />
							<Route path="/terms-of-use" element={<TermsAndConditions config={config} />} />
							<Route path="/not-found" element={<NotFound />} />
							<Route path="/" element={<ProductsPage />} />
							<Route path="*" element={<NotFound />} />
						</Routes>
					</BrowserRouter>
					<Footer />
				</>
				: null}
		</div>
	);
}

export default App;