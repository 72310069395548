import { ButtonField, Iconsvg } from "@wk/components-react16";
import React from "react";
import { Product } from "../models/product";
import CongratsIcon from '../assets/images/congrats_icon.svg'

const OrderDetails = (props: any) => {
 	let tax = props.checkoutProduct?.totalTax;
	return (
		<div className="order-details-section">
			<h2 className="border-bottom pb-em fw-500">Order Details</h2>
			{/* <span title="close" className="wk-icon-close d-none-lg custom-close" onClick={props.onCloseClick}>&#x2715;</span> */}
			<div className="wk-grid-page-container">
				<div className="wk-grid custom-padding border-bottom padding-bottom-2">
					<div className="wk-column-8 wk-column-9-xs">
						<h4 className="d-inline font-weight-500">{props.checkoutProduct.product?.productTitle || ''} </h4>
						<span className="display-block">{props.checkoutProduct.product?.productSubTitle || ''}</span>
					</div>
					<div className="wk-column-4 wk-column-3-xs text-right">
						<h4 className="d-inline font-weight-500">{props.checkoutProduct.product?.priceText || ''}</h4>
					</div>
				</div>

				{props?.checkoutProduct?.addOnProducts
					?.filter((a: Product) => a.isPurchased)
					.map((a: Product) =>
						<>
							<div className="wk-grid custom-padding border-bottom padding-bottom-1 padding-top-1" key={a.sourceCodeID}>
								<div className="wk-column-8 wk-column-9-xs" key={`div-2-${a.sourceCodeID}`}>
									<h4 className="d-inline font-weight-500" key={`h4-${a.sourceCodeID}`}>{a.productTitle || ''}</h4>
									<div style={{ paddingLeft: '-1rem' }} key={`div-3-${a.sourceCodeID}`}>
										{props.handleAddOnProductPurchase && <ButtonField size={'small'} mode={'text'} key={`bt-${a.sourceCodeID}`}>
											<button className="wk-button custom-padding" onClick={() => props.handleAddOnProductPurchase(a.productID, false)}>Remove</button>
										</ButtonField>}
									</div>
								</div>
								<div className="wk-column-4 wk-column-3-xs text-right" key={`div-4-${a.sourceCodeID}`}>
									<h4 className="d-inline font-weight-500" key={`h4-2-${a.sourceCodeID}`}>{ a.priceText || ''}</h4>
								</div>
							</div>
						</>
					)}
				{props.checkoutProduct?.membershipDiscount > 0 &&
					<div className="wk-grid custom-padding margin-top-1">
						<div className="wk-column-8 wk-column-9-xs">
							<span className="d-inline">Member Discount: </span>
						</div>
						<div className="wk-column-4 wk-column-3-xs text-right">
							<h4 className="d-inline green-text font-weight-400">- ${props.checkoutProduct?.membershipDiscount?.toFixed(2)}</h4>
						</div>
					</div>

				}
				<div className="wk-grid custom-padding margin-top-1">
					<div className="wk-column-8 wk-column-9-xs">
						<span className="d-inline">Subtotal: </span>
					</div>
					<div className="wk-column-4 wk-column-3-xs text-right">
						<h4 className="d-inline font-weight-400">${props.checkoutProduct?.priceWithoutTax?.toFixed(2) || ''}</h4>
					</div>
				</div>
				{props?.checkoutProduct?.showTotalPriceCalculation &&
					<>
						<div className="wk-grid custom-padding margin-top-1">
							<div className="wk-column-8 wk-column-9-xs">
								<span className="d-inline">Tax: </span>
							</div>
							<div className="wk-column-4 wk-column-3-xs text-right">
								<h4 className="d-inline font-weight-400">${tax}</h4>
							</div>
						</div>
						<div className="wk-grid custom-padding margin-top-1">
							<div className="wk-column-8 wk-column-9-xs">
								<span className="d-inline font-weight-500">Total: </span>
							</div>
							<div className="wk-column-4 wk-column-3-xs text-right">
								<h4 className="d-inline font-weight-500">${props.checkoutProduct?.orderGrandTotal}</h4>
							</div>
						</div>
					</>
				}
				<div className="wk-grid custom-padding margin-top-1" />

				{props.checkoutProduct?.membershipDiscount > 0 &&
					<div className="wk-grid custom-padding border-bottom border-top ">
						<div className="wk-column-12 wk-column-12-xs">
							<span className="pt-1 pb-1 display-block"> <img src={CongratsIcon} className="mr-1 float-left" alt="congratulation-icon"></img><strong> Congratulations!</strong> You've saved<strong> ${props.checkoutProduct?.membershipDiscount}</strong> with your purchase.</span>
						</div>
					</div>
				}
				{props.checkoutProduct?.addOnProducts?.filter((a: Product) => !a.isPurchased).length > 0 &&
					<div className="wk-grid custom-padding border-bottom padding-bottom-2">
						<div className="wk-column-12 custom-padding margin-top-1">
							<span className='font-weight-500' style={{ marginTop: '0.5rem' }}>Recommended for you: </span>
						</div>
						{props.checkoutProduct.addOnProducts
							.filter((a: Product) => !a.isPurchased)
							.sort((first: Product, second: Product) => first.productID - second.productID)
							.map((a: Product) =>
								<div className="wk-grid wk-column-12 grey-background" key={`m-${a.productID}`} style={{ alignItems: 'center', padding: '1rem' }}>
									<div className="wk-column-8 wk-column-9-xs" key={`mdiv-2-${a.sourceCodeID}`}>
										<div key={`pt-${a.productID}`} >
											<span className="d-inline font-weigth-500" key={`span-${a.sourceCodeID}`}>{a.productTitle || ''}</span>
										</div>
										<div key={`pp-${a.productID}`}>
											{a.newPriceText && <h4 className={`d-inline font-weight-500 ${a.newPriceText ? 'strike-through' : ''}`}>{a.priceText || ''}</h4>}
											<h4 className="d-inline font-weight-500" key={`h4-2-${a.sourceCodeID}`}>  {a.newPriceText || a.priceText || ''}</h4>
										</div>
									</div>
									<div className="wk-column-4 wk-column-3-xs text-right" key={`p-${a.productID}`}>
										<ButtonField mode={'ghost'} size={'small'} key={`bt-${a.productID}`}>
											<button className="wk-button" onClick={() => props.handleAddOnProductPurchase(a.productID, true)}>Add</button>
										</ButtonField>
									</div>
								</div>
							)}

					</div>
				}

			</div>
			<span className="margin-top-2 display-block d-none-lg link-text font-weight-400" onClick={props.onCloseClick}><Iconsvg name='arrow-left'></Iconsvg> Back to previous screen</span>
		</div>
	);
};
export default OrderDetails;
