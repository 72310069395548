import React from 'react'

const Header = () => {
  return (
    <div className='white-bg'>
      <div className="wk-grid-page-container docs-grid-container w-85vw">
        <div className="wk-grid column-split pl-0 header-custom-gap horizontal-center-align">
          <div className="wk-column-6 wk-column-6-xs header-custom-height">
            <img className='pd-x logo-sm' src="/images/OnDemand_logo.png" alt="Learners Digest logo" />
          </div>
          <div className="wk-column-6 wk-column-12-xs">
            <div className="text-right">
              <h6 className="mb-0 help-text">Contact Sales: 800 501 2303</h6>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Header;