import React from 'react'

const Footer = () => {
  const currentYear = new Date().getFullYear();
  return (
    <footer className='footer'>
      <div className='Footer-section'>
        <div className="wk-grid-page-container docs-grid-container w-85vw">
          <div className="wk-grid pl-0 custom-row-mb">

            <div className="wk-column-9 wk-column-12-xs wk-column-7-s">
              <img src="/images/OnDemandLogo-white.png" className="p-bottom-4 logo-sm ml large-device" alt="Learners Digest white logo" />
              <div> <a target='_blank' href="/terms-of-use" className="wk-white-text footer-links">Terms of Use &nbsp;&nbsp;</a>
                <a target='_blank' href="/privacy" className="wk-white-text footer-links"> Privacy &amp; Cookies&nbsp;&nbsp;</a>
                <a target='_blank' href="/privacy/california" className="wk-white-text footer-links"> Your California Privacy Choices <img className="privacy-pill" src="/images/privacy-pill.png" alt='privacy-icon' /></a><br />
                <span className="wk-white-text margin-top-4 d-block">© {currentYear} LDI BCM LLC, All rights reserved.</span>
              </div>
            </div>
            <div className="wk-column-3 wk-column-12-xs wk-column-5-s">
            <img src="/images/OnDemandLogo-white.png" className="p-bottom-4 logo-sm ml small-device" alt="Learners Digest white logo" />
            <h3 className="h3-title">Customer Service</h3>
                                    <h6 className=" white-color">Monday-Friday: 6:00am-5:00pm PT </h6>
                                    <h6 className=" white-color">Telephone: 1 (800) 501-2303 </h6>
                                    <h6 className=" white-color">
                                       <span>Email: </span><a href="mailto:support@ondemand.org" className="emailhref"> support@OnDemand.org</a></h6>
                                      
            </div>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer;