import React, { useEffect, useRef, useState } from "react";
import { CheckoutProduct } from "../models/product";
import User from "../models/user";
import checkoutService from "../services/checkoutService";


declare global {
	interface Window {
		PaymentRequest: any
	}
}

const ApplePay = (props: any) => {

	// const [applePayTerms, setApplePayTerms] = useState(false);
	const { user, countries, provinces, handlePayment, handleUserAndProductUpdate, setErrors, errors } = props;

	const productRef: any = useRef();
	const agreeTermsRef: any = useRef();
	const [productCopy, setProductCopy] = useState<CheckoutProduct>({} as CheckoutProduct);
	const [tax, setTax] = useState<number>(0);
	const [total, setTotal] = useState<number>(0);

	useEffect(() => {
		setProductCopy(props.product);
		productRef.current = props.product
	}, [props.product])

	useEffect(() => {
		agreeTermsRef.current = props.agreeTerms as any;
	}, [props.agreeTerms])

	useEffect(() => {
		if (total) {
			setProductCopy({ ...productRef.current, totalTax: +tax, orderGrandTotal: +total });
		}
	}, [tax, total])  //eslint-disable-line

	useEffect(() => {
		productRef.current = productCopy as any;
	}, [productCopy])


	function getAppleConfigs(key) {
		let value = "";
		switch (key) {
			case 'merchantId':
				value = props.config.applePay.merchantId;
				break;
			case 'merchantName':
				value = props.config.applePay.merchantName;
				break;
			case 'applePayEnabled':
				value = props.config.applePay.enable;
				break;
			case 'supportedMethods':
				value = props.config.applePay.supportedMethodURL
		}
		return value;
	}

	async function validateMerchant(eventCheck) {
		let data = {
			url: eventCheck.validationURL,
			body: {
				merchantIdentifier: getAppleConfigs("merchantId"),
				displayName: getAppleConfigs("merchantName"),
				initiative: "web",
				initiativeContext: window.location.hostname
			}
		}

		const response = await checkoutService.GetApplePayMerchantSession(data);
		return response;

	}


	async function onApplePayButtonClicked() {

		if (!window.PaymentRequest) {
			return;
		}

		if (!agreeTermsRef.current) {
			setErrors({ ...errors, agreeTerms: agreeTermsRef.current ? "" : "Please read and agree the terms" })
			return;
		}

		try {

			// Define PaymentMethodData
			const paymentMethodData = [{
				"supportedMethods": getAppleConfigs("supportedMethods"),
				"data": {
					"version": 3,
					"merchantIdentifier": getAppleConfigs("merchantId"),
					"merchantCapabilities": [
						"supports3DS"
					],
					"supportedNetworks": [
						"amex",
						"discover",
						"masterCard",
						"visa"
					],
					"countryCode": "US"
				}
			}];

			// let priceForTax = productRef.current.priceWithoutTax

			let regularPrice = productRef.current.priceWithoutTax + (productRef.current.membershipDiscount ? productRef.current.membershipDiscount : 0) //model.Cart.CartLines[0].ProductRegularPrice;
			let tax = productRef.current.totalTax
			let newTotal = productRef.current.orderGrandTotal

			// Define PaymentDetails
			const paymentDetails = {
				"total": {
					"label": getAppleConfigs('merchantName'),
					"amount": {
						"value": newTotal,
						"currency": "USD"
					}
				},
				"displayItems": [
					{
						"label": "Subtotal",
						"amount": {
							"value": regularPrice,
							"currency": "USD"
						}
					},
				],
				"shippingOptions": [
					{
						"id": "standardShipping",
						"label": "Digitally Shipped via email",
						"amount": {
							"value": "0.00",
							"currency": "USD"
						},
						"selected": true
					}
				],
				"modifiers": [
					{
						"supportedMethods": getAppleConfigs("supportedMethods"),
					}
				]
			};

			paymentDetails.displayItems.push(
				{
					"label": "Tax",
					"amount": {
						"value": tax,
						"currency": "USD"
					}
				},
			)

			// Define PaymentOptions
			const paymentOptions = {
				"requestPayerName": true,
				"requestBillingAddress": false,
				"requestPayerEmail": false,
				"requestPayerPhone": false,
				"requestShipping": true,
				"shippingType": "shipping"
			};

			// Create PaymentRequest
			const request = new window.PaymentRequest(paymentMethodData, paymentDetails, paymentOptions);

			request.onmerchantvalidation = (eventCheck: any) => {

				const merchantValidationeventCheck = async (eventCheck) => {
					const merchantSession: any = await validateMerchant(eventCheck);
					eventCheck.complete(merchantSession);
				}
				merchantValidationeventCheck(eventCheck);
			};

			request.onpaymentmethodchange = (eventCheck: any) => {
				const paymentDetailsUpdate = {
					'total': paymentDetails.total
				};
				eventCheck.updateWith(paymentDetailsUpdate);
			};

			request.onshippingoptionchange = (eventCheck: any) => {
				const paymentDetailsUpdate = {
					'total': paymentDetails.total
				};
				eventCheck.updateWith(paymentDetailsUpdate);
			};

			request.onshippingaddresschange = (eventCheck: any) => {

				const shippingAddressChangeEvent = async (event: any) => {
					if (event && event.currentTarget && event.currentTarget.shippingAddress) {
						const countryName = event.currentTarget.shippingAddress.country;
						const postalCode = event.currentTarget.shippingAddress.postalCode;
						const state = event.currentTarget.shippingAddress.region;
						const countryId = ['us', 'united states', 'usa'].includes(countryName.toLocaleLowerCase()) ? 192 : ['canada', 'ca'].includes(countryName.toLocaleLowerCase()) ? 37 : 0;

						var calculatedTotal: any = await CalculateTaxForAppleOrGooglePay(countryName, postalCode, state, countryId, paymentDetails);

						const userupdate = { ...user, countryId: countryId, country: countryId !== 0 ? countries.filter(a => a.key === `${countryId}`)[0].value : countryName, state: state, stateId: countryId === 37 ? provinces.filter(a => a.key === state)[0]?.value ?? 0 : 0 }
						const productUpdate = { ...productRef.current, orderGrandTotal: +calculatedTotal.newTotal.amount.value, totalTax: +calculatedTotal.displayItems.filter(a => a.label.toUpperCase() === 'TAX')[0].amount.value }

						handleUserAndProductUpdate(userupdate, productUpdate)
						setTax(+calculatedTotal.displayItems.filter(a => a.label.toUpperCase() === 'TAX')[0].amount.value);
						setTotal(+calculatedTotal.newTotal.amount.value);
						
						event.updateWith(calculatedTotal);
					}
					else {
						event.updateWith({
							'total': paymentDetails.total
						});
					}
				}
				shippingAddressChangeEvent(eventCheck);
			}

			const response = await request.show();

			var paymentDataString = JSON.stringify(response.details.token.paymentData);
			var paymentDataBase64 = btoa(paymentDataString);

			let cardTypeAndMaskedPan = getCardTypeAndMaskedPanFromApplePayResponse(response.details.token.paymentMethod)

			const firstName = response.shippingAddress.recipient.split(' ')[0];
			const lastName = response.shippingAddress.recipient.split(' ')[1]
			const newUser: User = { ...user }
			newUser.firstName = firstName;
			newUser.lastName = lastName;
			newUser.state = response.shippingAddress.region;
			newUser.country = response.shippingAddress.country;
			newUser.countryId = ['us', 'united states', 'usa'].includes(response.shippingAddress.country.toLocaleLowerCase()) ? 192 : ['canada', 'ca'].includes(response.shippingAddress.country.toLocaleLowerCase()) ? 37 : 0;
			newUser.zipCode = response.shippingAddress.postalCode;

			await handlePayment(paymentDataBase64, 3, cardTypeAndMaskedPan, newUser, productRef.current, response);

		}
		catch (e) {
			console.log(e);
		}
	}


	async function CalculateTaxForAppleOrGooglePay(countryCode: string, zipCode: string, stateCode: string, countryId: number, paymentDetails: any) {

		const tax = await checkoutService.AppliedTaxByCountryCode(countryCode, countryId, stateCode, zipCode, productRef.current.priceWithoutTax)
		if (tax.isTaxValid) {
			const paymentDetailsUpdate: any = {
				"total": {
					"label": getAppleConfigs('merchantName'),
					"amount": {
						"value": tax.totalAmount.toFixed(2),
						"currency": "USD"
					}
				},
				"displayItems": paymentDetails.displayItems,
			};
			paymentDetailsUpdate.newTotal = paymentDetails.total;
			paymentDetailsUpdate.newTotal.amount.value = tax.totalAmount.toFixed(2).toString();

			paymentDetailsUpdate.displayItems.filter(a => a.label === "Tax")[0].amount.value = `${tax.salesTaxAmount.toFixed(2)}`;
			return paymentDetailsUpdate;
		}
		else {
			const paymentDetailsUpdate: any = {
				"total": {
					"label": getAppleConfigs('merchantName'),
					"amount": {
						"value": productRef.current.priceWithoutTax,
						"currency": "USD"
					}
				},
				"displayItems": paymentDetails.displayItems,
				"shippingAddressErrors": {
					"postalCode": "Postal Code provided in address is incorrect"
				}
			};
			paymentDetailsUpdate.newTotal = paymentDetails.total;
			paymentDetailsUpdate.newTotal.amount.value = productRef.current.priceWithoutTax;

			paymentDetailsUpdate.displayItems.filter(a => a.label === "Tax")[0].amount.value = "0";
			return paymentDetailsUpdate;
		}
	}


	function getCardTypeAndMaskedPanFromApplePayResponse(paymentResponse) {

		let cardType = '';
		if (paymentResponse.network.toLowerCase() === "amex")
			cardType = '003'
		else if (paymentResponse.network.toLowerCase() === "discover")
			cardType = '004'
		else if (paymentResponse.network.toLowerCase() === "mastercard")
			cardType = '002'
		else if (paymentResponse.network.toLowerCase() === "visa")
			cardType = '001'
		else
			cardType = '001'

		return {
			cardType: cardType,
			maskedPan: paymentResponse.displayName.slice(Math.max(paymentResponse.displayName.length - 4, 1))
		}
	}
	return (
		<div className="apple-pay">
			<div>
				<div className="apple-pay-button apple-pay-button-black" id="apple-pay" onClick={onApplePayButtonClicked}></div>
			</div>
		</div>

	)
}
export default ApplePay;