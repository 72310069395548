import {
    ButtonField,
    ButtonFieldIcon,
    Iconsvg,
    InputField,
    RadioField,
    RadioGroupField,
    SelectField,
    Tooltip,
    TooltipBody,
} from "@wk/components-react16";
import React, { useEffect, useRef, useState } from "react";
import ApplePay from "./applepay";
import { embedFlexMicroformScript } from './embedMicroform';
import { KeyValuePair } from "../models/keyvalue";
import paymentService from "../services/paymentService";
import checkoutService from "../services/checkoutService";
import './microformStyles.css'
import PaymentsLearnMoreContitions from "../checkout/paymentLearnMoreCondition";
import { GooglePay } from "./googlePay";


declare global {
    interface Window {
        Flex: any;
        google: any;
        PaymentRequest: any;
    }
}

const PaymentInformation = (props: any) => {
    const [selectedOption, setSelectedOption] = useState('credit');
    const [isOpen, setIsOpen] = useState(false);
    const [countries, setCountries] = useState<KeyValuePair[]>([] as KeyValuePair[])
    const [provinces, setProvinces] = useState<KeyValuePair[]>([] as KeyValuePair[])
    // const [loadMicroform, setLoadMicroform] = useState(false);
    const [microform, setMicroform] = useState<any>(null);
    const ccNumberRef = useRef(null);
    const cvvRef = useRef(null);
    const [expiryMonth, setExpiryMonth] = useState('');
    const [expiryYear, setExpiryYear] = useState('');
    const [agreeTerms, setAgreeTerms] = useState(false);
    const [errors, setErrors] = useState<any>({});
    const [microformErrors, setMicroformErrors] = useState<any>({});
    const [IsPayButtonDisabled, setIsPayButtonDisabled] = useState(true);
    const [isApplePayAvailable, setIsApplePayAvailable] = useState(false);

    const { user, setUser, handleTaxUpdateChange, handleZipCodeRemoval, resetTaxForProduct, setIsLoading } = props;

    const handleRadioChange = (event: any) => {
        setSelectedOption(event.target.value);
    };
    const openModal = () => {
        setIsOpen(true)
    }
    const handleUserRequest = () => {
        setIsOpen(false)
    }

    useEffect(() => {
        const fetchProvinces = async () => {
            var result = await checkoutService.GetStates(37);
            setProvinces(result.data.map((state: any) => ({ key: state.stateCode, value: state.stateName })));
        }
        fetchProvinces();

        const details = {
            total: {
                label: "Total",
                amount: {
                    currency: "USD",
                    value: props.product.orderGrandTotal,
                },
            },
        };

    if (window.PaymentRequest) {
            new window.PaymentRequest(
                [{ supportedMethods: props.config.applePay.supportedMethodURL }],
                details
            ).canMakePayment()
                .then((res) => setIsApplePayAvailable(res ? true : false));
        }
        loadFlexMicroform(props.config.cybersourceAssetURL)

    }, []) //eslint-disable-line

    useEffect(() => {
        setupFlexMicroform();
    }, [microform]); //eslint-disable-line

    useEffect(() => {
        const fetchCountries = async () => {
            if (selectedOption === 'credit') {
                loadFlexMicroform(props.config.cybersourceAssetURL)
                try {
                    const response = await checkoutService.GetCountries();
                    setCountries(response.data);
                    setUser({ ...user, countryId: response.data[0].key, country: response.data[0].value });
                } catch (err) {
                    console.error('Error fetching countries:', err);
                }
            }
        };
        setAgreeTerms(false)
        fetchCountries();
    }, [selectedOption]); //eslint-disable-line

    useEffect(() => {
        let fieldErrors = validatePaymentFields();
        let fieldErrorsPresent = false;
        let microformErrorsPresent = false
        
        if (fieldErrors && Object.keys(fieldErrors).length > 0) {
            if (Object.keys(fieldErrors).filter((a: any) => fieldErrors[a].length).length > 0)
                fieldErrorsPresent = true;
            else
                fieldErrorsPresent = false;
        }

            if (microformErrors && Object.keys(microformErrors).length > 0) {
                if (Object.keys(microformErrors).filter((a: any) => microformErrors[a].length).length > 0)
                    microformErrorsPresent = true;
                else
                    microformErrorsPresent = false
            }
        setIsPayButtonDisabled(fieldErrorsPresent || microformErrorsPresent)

    }, [errors, microformErrors]) //eslint-disable-line

    useEffect(() => {
        
        if (+user.countryId === 37 && selectedOption === "credit") {
            handleTaxUpdateChange(provinces[0].value, true)
        }

    }, [user.countryId]) //eslint-disable-line

    const handleZipCodeChange = async (e: any) => {
        let zipCode = e.target.value.slice(0, 5);

        if (/^[0-9]*$/.test(zipCode)) {
            setUser({ ...user, zipCode: zipCode });
            setErrors({ ...errors, zipCode: "" })

            if (zipCode !== user.zipCode && zipCode.length === 5) {
                handleTaxUpdateChange(zipCode).then((isTaxValid) => {
                    if (!isTaxValid)
                        setErrors({ ...errors, zipCode: "Entered zip code is invalid" })
                })

            }
            else
                handleZipCodeRemoval()
        }
        if (zipCode.length < 5 || zipCode.length > 5)
            setErrors({ ...errors, zipCode: "Please enter valid zip code" })
        else
            setErrors({ ...errors, zipCode: "" })

    }

    const handleCountryChange = async ({ target }: any) => {
        const selectedCountryId = target.value;

        // keep country Ids in config for province selection
        if (+selectedCountryId === 37) {
            setUser({ ...user, zipCode: '', countryId: +selectedCountryId, country: countries.filter(a => a.key === selectedCountryId)[0].value, state: provinces[0].value, stateId: provinces[0].key })
        }
        else {
            setUser({ ...user, zipCode: '', state: '', stateId: 0, countryId: +selectedCountryId, country: target.options[target.selectedIndex].text })
        }

        resetTaxForProduct();
    }

    const handleSetAgreeTerms = (e: any) => {
        setAgreeTerms(e);
        setErrors({ ...errors, agreeTerms: e ? "" : "Please read and agree the terms" })
    }

    const handleExpiryMonthChange = (month: string) => {
        month = month.slice(0, 2);
        if (/^[0-9]*$/.test(month)) {
            setExpiryMonth(`${month}`);
            if (month.slice(0, 2).length === 2) {
                if (+month <= 12) {
                    const currentDate = new Date();
                    if (+month < currentDate.getMonth() + 1 && +expiryYear === currentDate.getFullYear())
                        setErrors({ ...errors, month: "Expiry Month should be equal or greater than current month" })
                    else
                        setErrors({ ...errors, month: "" })
                }
                if (+month === 0 || +month > 12)
                    setErrors({ ...errors, month: "Please enter valid expiry month" })
            }
            else {
                setErrors({ ...errors, month: "Please enter valid expiry month in MM format" })
            }
        }
    }

    const handleExpiryYearChange = (year: string) => {
        const currentDate = new Date();
        const inputErrors = { ...errors }
        year = year.slice(0, 4);
        if (/^[0-9]*$/.test(year)) {
            setExpiryYear(year.slice(0, 4));
            if (+year < currentDate.getFullYear() || +year === 0)
                inputErrors.year = "Please enter valid expiry year";
            else
                inputErrors.year = ""

            if (+expiryMonth < currentDate.getMonth() + 1 && +year === currentDate.getFullYear())
                inputErrors.month = "Expiry Month should be equal or greater than current month"
            else if (inputErrors.month === "Expiry Month should be equal or greater than current month")
                inputErrors.month = ""

            setErrors(inputErrors)
        }
    }

    var customStyles = {
        '*': {
            'font-family': 'Fira Sans'
        },
        'input': {
            'font-size': '1rem;',
            'font-family': 'Fira Sans',
            'color': '#232323',
            'padding-left': '0.5rem',
            'padding-right': '0.5rem',
            'line-height': '1.25',
            'height': '2.5rem',
        },
        '::placeholder': {
            'color': '#232323', 'font-weight': '300', 'opacity': '1'
        },
        ':focus': {
            'color': 'blue'
        },
        'valid': {
            'color': 'green'
        },
        'invalid': {
            'color': 'red'
        }
    };

    const loadFlexMicroform = async (cyberSourceURL: any) => {
        embedFlexMicroformScript(cyberSourceURL)
            .then(async () => {
                const key = await paymentService.GetPublicKey();
                const flex = new window.Flex(key);
                setMicroform(flex.microform({ styles: customStyles }));
                setIsLoading(false)
            })
            .catch((err) => {
                console.error(err);
                setIsLoading(false)
            });
    }

    const setupFlexMicroform = () => {
        if (!microform) {
            return;
        }

        const number = microform.createField("number", {
            placeholder: "Enter Your Credit Card Number"
        });

        number.on('change', function (data) {
            if (!data.valid) {
                setMicroformErrors({ ...microformErrors, cardNumber: 'Please enter valid credit card number' })
            }
            else {
                setMicroformErrors({ ...microformErrors, cardNumber: '' })
            }
        });

        const securityCode = microform.createField("securityCode", {
            placeholder: "CVV"
        });

        securityCode.on('change', function (data) {
            if (!data.valid) {
                setMicroformErrors({ ...microformErrors, cvv: 'Please enter valid CVV' })
            }
            else {
                setMicroformErrors({ ...microformErrors, cvv: '' })
            }
        });

        // load microform elements
        number.load(ccNumberRef.current);
        securityCode.load(cvvRef.current);
    };

    const handleCardPayment = () => {
        const invalidFields = validatePaymentFields();
        if (microform) {
            const options = {
                expirationMonth: expiryMonth,
                expirationYear: expiryYear
            };

            if (Object.keys(invalidFields).filter((a: any) => invalidFields[a].length).length) {
                setErrors(invalidFields);
                return;
            }

            microform.createToken(options, function (err: any, token: any) {
                let invalidMicroformFields = { ...microformErrors }

                if (token)
                    props.handlePayment(token, 1);
                if (err) {
                    if (err.details && err.details.length) {
                        if (err.details.filter(a => a.location === 'securityCode').length) {
                            invalidMicroformFields.cvv = 'Please enter valid CVV'
                        }
                        if (err.details.filter(a => a.location === 'number').length) {
                            invalidMicroformFields.cardNumber = 'Please enter valid credit card number'
                        }
                    }
                    else if (err.reason === 'CREATE_TOKEN_VALIDATION_SERVERSIDE') {
                        props.setShowModal(true);
                        props.setModalHeader('Session Timeout');
                        props.setModalMessage('For your security, your session has timed out. Please re-enter your credit card information and place your order');
                        if (props.config.cybersourceAssetURL) {
                            loadFlexMicroform(props.config.cybersourceAssetURL);
                        }
                    }
                    // console.log(err);
                }

                if (Object.keys(invalidMicroformFields).filter((a: any) => invalidMicroformFields[a]).length)
                    setErrors(invalidMicroformFields);
            })
        }
    }

    const handleProvinceChange = ({ target }) => {
        setUser({ ...user, stateId: target.value, state: provinces.filter(a => a.key === target.value)[0].value })
        handleTaxUpdateChange(provinces.filter(a => a.key === target.value)[0].value, true)
    }

    const validatePaymentFields = () => {
        let inputErrors = { ...errors };

        if (!user.countryId)
            inputErrors.country = "Please select country"

        if (!user.firstName)
            inputErrors.firstName = "Please enter first name"

        if (!user.lastName)
            inputErrors.lastName = "Please enter last name"

        if (!user.zipCode && +user.countryId === 192)
            inputErrors.zipCode = "Please enter valid zipcode"

        if (!user.stateId && +user.countryId === 37)
            inputErrors.province = "Please select province"

        if (!expiryMonth)
            inputErrors.month = "Please enter valid expiry month"

        if (!expiryYear)
            inputErrors.year = "Please enter valid expiry year"

        if (!agreeTerms)
            inputErrors.agreeTerms = "Please read and agree the terms"

        return inputErrors;

    }

    const handleFirstNameChange = (firstName: string) => {
        const pattern = props.config.regex.firstName
        const regexPattern = new RegExp(pattern, "gm");

        if (firstName.match(regexPattern)) {
            setUser({ ...user, firstName: firstName.replace(/\t/g, "") });
            if (firstName.length > 0)
                setErrors({ ...errors, firstName: '' })
        }
    }

    const handleLastNameChange = (lastName: string) => {
        const pattern = props.config.regex.lastName
        const regexPattern = new RegExp(pattern, "gm");

        if (lastName.match(regexPattern)) {
            setUser({ ...user, lastName: lastName.replace(/\t/g, "") });
            if (lastName.length > 0)
                setErrors({ ...errors, lastName: '' })
        }
    }

    return (
        <>
            <div>
                <div className="wk-grid-page-container">
                    <div className="wk-grid custom-padding">
                        <div className="wk-column-12">
                            <RadioGroupField alignment="horizontal">
                                <RadioField label="Credit Card">
                                    <input
                                        type="radio"
                                        name="Credit"
                                        value="credit"
                                        checked={selectedOption === "credit"}
                                        onChange={handleRadioChange} />
                                </RadioField>
                                {props.config.applePay.enable && isApplePayAvailable &&
                                    <RadioField label="Apple Pay®">
                                        <input
                                            type="radio"
                                            name="apple-pay"
                                            value="applepay"
                                            checked={selectedOption === "applepay"}
                                            onChange={handleRadioChange} />
                                    </RadioField>
                                }
                                {props.config.googlePay.enable &&
                                    <RadioField label="Google Pay™">
                                        <input
                                            type="radio"
                                            name="google-pay"
                                            value="gpay"
                                            checked={selectedOption === "gpay"}
                                            onChange={handleRadioChange} />
                                    </RadioField>
                                }
                            </RadioGroupField>
                        </div>
                        {selectedOption === "credit" && (
                            <>
                                <div className="wk-column-5 wk-column-6-s wk-column-12-xs">
                                    <InputField label="First Name" labelFor="input-id-1-1" indicator="required">
                                        <input
                                            type="text"
                                            id="input-id-1-1"
                                            name="input-1-1"
                                            placeholder="Enter First Name"
                                            value={user?.firstName || ''}
                                            onChange={(e) => handleFirstNameChange(e.target.value)}
                                        />
                                        {errors && errors.firstName && <div className="text-danger">{errors.firstName}</div>}
                                    </InputField>
                                </div>
                                <div className="wk-column-5 wk-column-6-s wk-column-12-xs">
                                    <InputField label="Last Name" labelFor="input-id-1-1" indicator="required">
                                        <input
                                            type="text"
                                            id="input-id-1-1"
                                            name="input-1-1"
                                            placeholder="Enter Last Name"
                                            value={user?.lastName || ''}
                                            onChange={(e) => handleLastNameChange(e.target.value)}
                                        />
                                        {errors && errors.lastName && <div className="text-danger">{errors.lastName}</div>}
                                    </InputField>
                                </div>
                                <div className="wk-column-5 wk-column-6-s wk-column-12-xs">
                                    <div className="cg-field-header" style={{ marginBottom: '.09rem' }}>
                                        <label className="required" style={{ fontWeight: 500 }}>Credit Card</label>
                                    </div>
                                    <div id="ccContainer" ref={ccNumberRef}></div>
                                    {microformErrors && microformErrors.cardNumber && <div className="text-danger">{microformErrors.cardNumber}</div>}
                                </div>
                                <div className="wk-column-5 wk-column-6-s wk-column-12-xs">
                                    <div className="wk-grid custom-padding">
                                        <div className="wk-column-4 wk-column-12-xs">
                                            <InputField label="Month" labelFor="input-id-1-1" indicator="required">
                                                <input
                                                    type="text"
                                                    id="input-id-1-1"
                                                    name="input-1-1"
                                                    placeholder="MM" value={expiryMonth}
                                                    onChange={(e) => handleExpiryMonthChange(e.target.value)} />
                                                {errors && errors.month && <div className="text-danger">{errors.month}</div>}
                                            </InputField>
                                        </div>
                                        <div className="wk-column-4 wk-column-12-xs">
                                            <InputField label="Year" labelFor="input-id-1-1" indicator="required">
                                                <input
                                                    type="text"
                                                    id="input-id-1-1"
                                                    name="input-1-1"
                                                    placeholder="YYYY" value={expiryYear}
                                                    onChange={(e) => handleExpiryYearChange(e.target.value)} />
                                                {errors && errors.year && <div className="text-danger">{errors.year}</div>}
                                            </InputField>
                                        </div>
                                        <div className="wk-column-4 wk-column-12-xs">

                                            <div className="cg-field-header">
                                                <div className="wk-grid custom-padding" >
                                                    <div className="wk-column-9 wk-column-9-xs">
                                                        <label className='required display-flex-tab-mini' style={{ fontWeight: 500 }}>CVV</label>
                                                    </div>
                                                    <div className="wk-column-2 wk-column-2-xs">
                                                        <div className="button-top" key='tooltip-div'>
                                                            <ButtonFieldIcon>
                                                                <button type="button" id="button-top" className="button-top-margin display-flex" style={{ padding: "0px" }}>
                                                                    <Iconsvg size={16} name="info"></Iconsvg>
                                                                    <Iconsvg name="info" isFill></Iconsvg>
                                                                </button>
                                                            </ButtonFieldIcon>
                                                        </div>
                                                        <Tooltip
                                                            identifier="tooltip-top"
                                                            mode="interactive"
                                                            position="right"
                                                            targetSelector="#button-top">
                                                            <TooltipBody slot="tooltipBody">
                                                                This is the three-digit (4-digit for AmEx) code on the front or back of your credit card.
                                                            </TooltipBody>
                                                        </Tooltip>
                                                    </div>
                                                </div>
                                            </div>
                                            <div id="cvvContainer" ref={cvvRef}></div>
                                            {microformErrors && microformErrors.cvv && <div className="text-danger">{microformErrors.cvv}</div>}
                                        </div>
                                    </div>
                                </div>
                                <div className="wk-column-5 wk-column-6-s wk-column-12-xs">
                                    <SelectField
                                        label="Country"
                                        labelFor="country"
                                        indicator="required"
                                    >
                                        <select id="country" name="story1-1"
                                            value={user?.countryId || 0}
                                            onChange={handleCountryChange}>
                                            {
                                                countries.map((designation: KeyValuePair, index: number) => <option key={index} value={designation.key}>{designation.value}</option>)
                                            }
                                        </select>
                                        {errors && errors.country && <div className="text-danger">{errors.country}</div>}
                                    </SelectField>
                                </div>

                                {+user.countryId === 192 ?
                                    <div className="wk-column-5 wk-column-6-s wk-column-12-xs">
                                        <InputField label="Zip Code" labelFor="input-id-1-1" indicator="required">
                                            <input
                                                type="text"
                                                id="input-id-1-1"
                                                name="input-1-1"
                                                placeholder="Zip"
                                                value={user.zipCode || ''}
                                                pattern="[0-9]*"
                                                onChange={handleZipCodeChange} />
                                            {errors && errors.zipCode && <div className="text-danger">{errors.zipCode}</div>}
                                        </InputField>
                                    </div>
                                    : +user.countryId === 37 ?
                                        <div className="wk-column-5 wk-column-6-s wk-column-12-xs">
                                            <SelectField
                                                label="Province"
                                                labelFor="province"
                                                indicator="required"
                                            >
                                                <select id="province" name="story1-1"
                                                    value={user?.stateId || 0}
                                                    onChange={handleProvinceChange}>
                                                    {
                                                        provinces.map((province: KeyValuePair, index: number) => <option key={index} value={province.key}>{province.value}</option>)
                                                    }
                                                </select>
                                                {errors && errors.province && <div className="text-danger">{errors.province}</div>}
                                            </SelectField>
                                        </div>
                                        : <></>
                                }
                                <PaymentsLearnMoreContitions selectedOption={selectedOption} errors={errors} agreeTerms={agreeTerms} setAgreeTerms={handleSetAgreeTerms} config={props.config} isOpen={isOpen} handleUserRequest={handleUserRequest} openModal={openModal} />
                                <div className="wk-column-12 wk-column-12-xs">
                                    <ButtonField>
                                        <button type="button" style={{ marginRight: "5px" }} onClick={handleCardPayment}
                                            disabled={IsPayButtonDisabled}>
                                            Place Order
                                        </button>
                                    </ButtonField>
                                </div>
                            </>
                        )}
                        {selectedOption !== "credit" &&
                            <PaymentsLearnMoreContitions selectedOption={selectedOption} errors={errors} agreeTerms={agreeTerms} setAgreeTerms={handleSetAgreeTerms} config={props.config} isOpen={isOpen} handleUserRequest={handleUserRequest} openModal={openModal} />
                        }
                        {selectedOption === "applepay" && (
                            <ApplePay agreeTerms={agreeTerms} setErrors={setErrors} errors={errors} config={props.config} user={user} setUser={setUser} countries={countries} provinces={provinces} handlePayment={props.handlePayment} product={props.product} handleUserAndProductUpdate={props.handleUserAndProductUpdate} />
                        )}
                        {selectedOption === "gpay" && (
                            <GooglePay agreeTerms={agreeTerms} setErrors={setErrors} errors={errors} config={props.config} user={user} setUser={setUser} countries={countries} provinces={provinces} handlePayment={props.handlePayment} product={props.product} handleUserAndProductUpdate={props.handleUserAndProductUpdate} />
                        )}
                        <div className="wk-column-12 wk-column-12-xs">
                            <span className="required-before">Required field</span>
                        </div>
                    </div>
                </div>
            </div>

        </>
    );
};

export default PaymentInformation;
